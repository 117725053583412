@use '@angular/material' as mat;
@charset "utf-8";

//@import "styles/variables";
//@import "styles/common/portal";
//@import "styles/vendor/fonts";
//@import "styles/vendor/font-awesome";
//@import "styles/vendor/bulma";
//@import "styles/components/button";
//html, body {
//  font-size: $fontSize;
//  font-family: $fontFamily;
//  color: palette(Blue Grey, 900);
//  background: $background;
//  -webkit-font-smoothing: subpixel-antialiased;
//  height: 100%;
//  width: 100%;
//}
/* You can add global styles to this file, and also import other style files */
/* 3rd Party CSS */
//$fa-font-path: '../node_modules/font-awesome/fonts';
//@import "~font-awesome/scss/font-awesome";
//@import "~quill/dist/quill.snow.css";
//@import "~highlight.js/styles/monokai-sublime.css";
//@import "~fullcalendar/dist/fullcalendar.min.css";

@import 'styles/components/waiter';
@import 'styles/components/toolbar';
@import 'styles/components/link';

/* Portal CSS */
@import 'styles/common/portal';

/* Angular Material */

@import 'styles/components/form';
@import 'styles/common/multiselect';

/* Typography */
$app-typography: mat.define-typography-config(
    $font-family: 'Barlow, sans-serif',
    $display-4: mat.define-typography-level(112px, 112px, 300),
    $display-3: mat.define-typography-level(56px, 56px, 400),
    $display-2: mat.define-typography-level(45px, 48px, 400),
    $display-1: mat.define-typography-level(34px, 40px, 400),
    $headline: mat.define-typography-level(24px, 32px, 400),
    $title: mat.define-typography-level(20px, 32px, 500),
    $subheading-2: mat.define-typography-level(14px, 28px, 400),
    $subheading-1: mat.define-typography-level(14px, 24px, 400),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $body-1: mat.define-typography-level(14px, 24px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500),
    $input: mat.define-typography-level(16px, 1.125, 400),
);

// Set up typography with above rules
@include mat.core($app-typography);

$app-primary: mat.define-palette(mat.$blue-palette);
$app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: portal-light-theme($app-primary, $app-accent, $app-warn);
// $app-theme: portal-dark-theme($app-primary, $app-accent, $app-warn);

// Create app styles
@include mat.all-component-themes($app-theme);
@include portal-theme($app-theme, $app-typography);

// Create the theme object (a Sass map containing all of the palettes).
$sidenav-theme: portal-dark-theme($app-primary, $app-accent, $app-warn);

.portal-sidenav-theme {
    @include mat.all-component-themes($sidenav-theme);
    @include portal-theme($sidenav-theme, $app-typography);
}
