@import '@angular/material/theming';

$box-shadow: 0 0 16px rgba(0, 0, 0, 10%);

@mixin button-theme() {
    .mat-raised-button:not([class*='mat-elevation-z']),
    .mat-stroked-button:not([class*='mat-elevation-z']) {
        box-shadow: $box-shadow;
    }

    .mat-flat-button:not([class*='mat-elevation-z']) {
        box-shadow: none
    }
}