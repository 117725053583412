@import "../variables";

.toolbar {
    z-index: 1000;

    &--sub {
        margin: 0 0 20px !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    &__search {
        margin-top: 25px;
    }
}
