@use '@angular/material' as mat;
@mixin portal-theme-switcher-theme($theme) {
    $background: map-get($theme, background);
    $accent: map-get($theme, accent);

    .portal-theme-switcher-menu {
        &.mat-menu-panel {
            background: mat.get-color-from-palette($background, selected-button);
            min-width: 200px;
            padding: 15px;
        }
        .portal-theme-switcher-item.active {
            background: mat.get-color-from-palette($accent, lighter);
            border-radius: 50%;
        }
    }
}
