@use '@angular/material' as mat;
@mixin portal-profile-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    .portal-profile__avatar {
        border: 8px solid mat.get-color-from-palette($background, card);
        box-shadow: none;
        animation: portal-profile__avatar 1s forwards 1;
        animation-delay: 1.2s;
    }
    .portal-profile__content {
        .mat-icon {
            color: mat.get-color-from-palette($accent, default);
        }
        .mat-tab-label {
            min-height: 72px;
            flex: 1 1 auto;
        }
        .mat-tab-body-wrapper {
            padding: 36px;
        }
        .mat-tab-body-content {
            margin-bottom: 0;
        }
        textarea {
            min-height: 100px;
        }
    }

    @keyframes portal-profile__avatar {
        0% {
            box-shadow: none;
        }
        100% {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 0px 0 15vw rgba(mat.get-color-from-palette($accent, 800), 0.7),
                0px 0px 0 30vw rgba(mat.get-color-from-palette($accent, 700), 0.6), 0px 0px 0 45vw rgba(mat.get-color-from-palette($accent, 600), 0.5);
        }
    }
}
