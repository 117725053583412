@use '@angular/material' as mat;
@mixin portal-layout-loader-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .portal-layout-loader {
        background: mat.get-color-from-palette($background, background);
        color: mat.get-color-from-palette($foreground, text);
    }
}
