$base-color: #2196f3;
.multiselect-dropdown {
    box-sizing: border-box;
    .selected-item {
        pointer-events: none;
        & > a {
            display: none;
        }
    }
    .dropdown-btn {
        display: flex !important;
        box-sizing: border-box;
        .selected-item {
            box-sizing: border-box;
            border: 1px solid $base-color !important;
            background: $base-color !important;
        }
        .dropdown-down {
            box-sizing: border-box;
        }
        .dropdown-up {
            box-sizing: border-box;
        }
    }
}

.dropdown-list {
    box-sizing: border-box;
    overflow: hidden;
    .filter-textbox {
        input {
            box-sizing: border-box;
        }
    }
}

.multiselect-item-checkbox input[type='checkbox'] {
    box-sizing: border-box;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
    border-color: $base-color !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
    color: $base-color !important;
    border: 2px solid $base-color !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
    background-color: $base-color !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
    background: $base-color !important;
}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px $base-color !important;
    }
}

ng-multiselect-dropdown.ng-invalid {
    color: #f44336;
    .dropdown-btn {
        border-color: #f44336 !important;
    }
}