@import '@angular/material/theming';

$box-shadow: 0 0 16px rgba(0,0,0, 10%);

@mixin expansion-panel-theme() {
    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: $box-shadow;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
        background: rgba(0, 0, 0, 0.1);
    }
}