@import '../variables';

.mat-form-field {
    .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-underline {
            position: initial !important;
            display: block;
            margin-top: -1px;
        }

        .mat-form-field-subscript-wrapper,
        .mat-form-field-ripple {
            position: initial !important;
            display: table;
        }

        .mat-form-field-subscript-wrapper {
            min-height: calc(1em + 1px);
            padding: 0 0 .25em 0;
        }
    }
}
