@use '@angular/material' as mat;
@import '../../../../styles/common/utils';

@mixin portal-footer-theme($theme) {
    $accent: map-get($theme, accent);

    .portal-footer {
        a {
            color: mat.get-color-from-palette($accent, default);
        }
    }
}
