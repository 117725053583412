@import '../common';

.link {
    font-size: 14px;
    color: #2196f3;
    text-decoration: none;
    outline: none;
    transition: 0.2s;
    cursor: pointer;

    &:hover,
    &:focus {
        color: darken(#2196f3, 10%);
    }

    &--dotted {
        border-bottom: 1px dashed #2196f3;
    }
}
