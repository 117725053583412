@use '@angular/material' as mat;
@mixin portal-dashboard-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    .portal-dashboard-page-wrapper {
        background: mat.get-color-from-palette($background, background);
    }

    // Widget colors
    .portal-widget__color-primary {
        color: mat.get-color-from-palette($primary, default);
    }
    .portal-widget__color-accent {
        color: mat.get-color-from-palette($accent, default);
    }

    // Widget heading
    .portal-dashboard-page-wrapper .portal-widget__heading {
        border-color: mat.get-color-from-palette($primary, default);
        &:after {
            background-color: mat.get-color-from-palette($accent, default);
        }
    }
    [dir='rtl'] .portal-dashboard-page-wrapper .portal-widget__heading {
        padding-right: 12px;
        padding-left: 0;
        border-left-width: 0;
        border-right-width: 2px;
        border-right-style: solid;
        &:after {
            right: -2px;
            left: auto;
        }
    }

    // Widget content
    .portal-widget__content {
        .mat-card {
            box-sizing: border-box;
            height: 100%;
        }
    }
    .portal-widget__content--no-padding {
        .mat-card {
            padding: 0;
        }
    }
    .portal-widget__content--no-padding-bottom {
        .mat-card {
            padding-bottom: 0;
        }
    }

    // Widget content classes
    .portal-widget__padding {
        padding: 24px;
    }
    .portal-widget__padding-x {
        padding-left: 24px;
        padding-right: 24px;
    }
    .portal-widget__padding-y {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .portal-widget__content-divider {
        height: 1px;
        background: mat.get-color-from-palette($foreground, dividers);
    }

    // Primary widget
    .portal-widget__content--primary {
        .mat-card {
            background: linear-gradient(0deg, mat.get-color-from-palette($primary, default), mat.get-color-from-palette($primary, 600));
            color: mat.get-color-from-palette($primary, default-contrast);
        }
        .portal-widget__content-divider {
            background: rgba(mat.get-color-from-palette($primary, default-contrast), 0.2);
        }
    }

    // Ticker card item
    .ticker-item {
        border-left: 1px solid rgba(invert(mat.get-color-from-palette($background, card)), 0.075);
    }
}
