@use '@angular/material' as mat;
@mixin portal-examples($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .portal-example-page {
        display: grid;
        grid-template-rows: 450px auto;
        grid-gap: 24px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .portal-example-pager__header {
        grid-column: 1/-1;
        background: mat.get-color-from-palette($background, background);

        .portal-example-pager__header-content {
            margin-bottom: 150px;

            &--animation {
                animation: portal-pages__header-icon 4s infinite ease-in-out;
            }

            &--heading {
                font-weight: 200;
                font-size: 64px;
            }
        }
    }

    .portal-example-pager__content {
        grid-column: 2/-2;
        background: mat.get-color-from-palette($background, card);
        margin-top: -200px;
        padding: 60px;
        box-shadow: 0px -40px 100px -50px rgba(0, 0, 0, 0.3), -20px 0px 40px -50px rgba(0, 0, 0, 0.2),
            20px 0px 40px -50px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 10;

        a {
            color: mat.get-color-from-palette($accent, default);
        }

        .portal-example-pager__content--heading {
            font-weight: 300;
            font-size: 24px;
            line-height: 32px;
            margin: 40px 0 20px;
            padding-bottom: 3px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
    @keyframes portal-pages__header-icon {
        0%,
        100% {
            stroke: mat.get-color-from-palette($primary, default);
            color: mat.get-color-from-palette($primary, default);
        }
        75% {
            stroke: mat.get-color-from-palette($accent, default);
            color: mat.get-color-from-palette($accent, default);
        }
    }

    // IE 11 fixes
    @media all and (-ms-high-contrast: none) {
        .portal-example-page {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .portal-example-pager__content {
            width: 800px;
            max-width: 90%;
        }
        .portal-example-pager__header {
            min-height: 450px;
            width: 100%;
        }
        .portal-pages__header-icon {
            margin-top: 50px;
            margin-bottom: 50px;
            stroke: mat.get-color-from-palette($primary, default);
        }
        .portal-example-pager__header-content {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
}
