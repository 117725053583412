@import '../common';

$spinnerSize: 48px;
$spinnerBorderSize: 4px;
$backgroundOverlayWhite: rgba(255, 255, 255, 0.5);

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.waited {
    position: relative;
}

.waiter {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    width: 100%;
    z-index: 999;

    &--block {
        background-color: $backgroundOverlayWhite;
        background-image: url('/assets/images/wait-bg.gif');
    }

    &__spinner {
        display: inline-block;
        height: $spinnerSize;
        left: 50%;
        margin: (-$spinnerSize / 2) 0 0 (-$spinnerSize / 2);
        position: absolute;
        top: 50%;
        width: $spinnerSize;

        & > div {
            animation: rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border: $spinnerBorderSize solid palette(Deep Purple, 900);
            border-color: palette(Deep Purple, 900) transparent transparent transparent;
            border-radius: 50%;
            box-sizing: border-box;
            display: block;
            height: $spinnerSize;
            margin: $spinnerBorderSize;
            position: absolute;
            width: $spinnerSize;
        }

        & > div:nth-child(1) {
            animation-delay: -0.45s;
        }

        & > div:nth-child(2) {
            animation-delay: -0.3s;
        }

        & > div:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}
