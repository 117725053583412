@use '@angular/material' as mat;
@mixin portal-toolbar($theme) {
    $foreground: map-get($theme, foreground);

    // Brand image & text
    .portal-toolbar-brand {
        width: 100%;
        max-height: 100%;
        padding: 16px 0;
        box-sizing: border-box;
        object-fit: contain;
    }
    .portal-toolbar-brand-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mat-toolbar {
        color: mat.get-color-from-palette($foreground, app-bar);
    }
}
